// /Users/admin/leonid-landing/src/App.js
import lottie from "lottie-web"
import React, { useEffect, useRef } from "react"
import "./App.css"

function App() {
  const animationContainer = useRef(null)

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/animation.json",
    })

    animation.setSpeed(0.66666)

    return () => {
      animation.destroy() // optional clean up for unmounting
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <div className="animation-container" ref={animationContainer} />
        <p>to your new domain Leonid!</p>
      </header>
    </div>
  )
}

export default App
